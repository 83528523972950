<template>
  <a-modal
    v-model:visible="modalData.visible"
    :title="$t('common.top_up')"
    :maskClosable="false"
  >
    <a-spin :spinning="modalLoading">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="modalForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('finance.recharge_currency')" name="toCurrencyCode">
          <a-select
            v-model:value="modalForm.toCurrencyCode"
            optionFilterProp="search-key"
            show-search
            @change="handleToCurrencyCodeChange"
          >
            <a-select-option
              v-for="(item, index) in currencyList"
              :key="index"
              :value="item.key"
              :search-key="item.cnName + item.enName"
              >{{ getCnOrEnName(item) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('finance.money')" name="rechargeAmount">
          <a-input-number
            style="width: 100%"
            ref="rechargeAmountRef"
            v-model:value="modalForm.rechargeAmount"
            :allowClear="true"
            :min="0"
            :max="1000000"
            :loading="inputLoading"
          />
        </a-form-item>
        <a-form-item :label="$t('finance.payment_currency')" name="fromCurrencyCode">
          <a-select
            v-model:value="modalForm.fromCurrencyCode"
            optionFilterProp="search-key"
            show-search
            @change="modalForm.fromCurrencyId = null"
          >
            <a-select-option
              v-for="(item, index) in getPaymentCurrenciesList()"
              :key="index"
              :value="item.key"
              :search-key="item.cnName + item.enName"
              >{{ getCnOrEnName(item) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_method')" name="fromCurrencyId">
          <a-select
            v-model:value="modalForm.fromCurrencyId"
            optionFilterProp="search-key"
            show-search
            @change="handleFromCurrencyCodeChange"
          >
            <a-select-option
              v-for="(item, index) in getSelectPaymentCurrenciesList()"
              :key="index"
              :value="item.id"
              :search-key="$t($enumLangkey('rechargeType', item.payeeAccountType))"
              >{{ $t($enumLangkey('rechargeType', item.payeeAccountType)) }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('finance.exchange_rate')">
          <span>{{ modalFormDetail.exchangeRate }}</span>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_amount')">
          <span>{{ modalFormDetail.payInfo }} {{ modalFormDetail.payMoney }}</span>
        </a-form-item>
        <a-form-item :label="$t('menu.account_info')">
          <div v-if="modalFormDetail.accountInfo">
            <div v-if="modalFormDetail.accountInfo.accountNo">{{ $t("finance.account_number") }}: {{ modalFormDetail.accountInfo.accountNo }}</div>
            <div v-if="modalFormDetail.accountInfo.accountName">{{ $t("finance.title_of_account") }}: {{ modalFormDetail.accountInfo.accountName }}</div>
            <div v-if="modalFormDetail.accountInfo.bank">{{ $t("finance.bank_name") }}: {{ modalFormDetail.accountInfo.bank }}</div>
            <div v-if="modalFormDetail.accountInfo.swiffCode">SWIFF CODE: {{ modalFormDetail.accountInfo.swiffCode }}</div>
          </div>
          <span v-else style="color: #CCCCCC">{{ $t('finance.please_select_payment_currency_first') }}</span>
        </a-form-item>
      </a-form>
    </a-spin>
    <template #footer>
      <a-button key="back" @click="handleClose">{{
        $t("common.close")
      }}</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="modalData.loading"
        @click="handleDistribution"
        >{{ $t("finance.create_recharge") }}</a-button
      >
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs, ref, watch } from 'vue'
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import {
  Button,
  Modal,
  Form,
  Select,
  Input,
  InputNumber,
  Spin,
  message,
} from "ant-design-vue";
import {
  getExchangeRate,
  getPayeeAccountList,
  getReChargePayMoney,
  createReChargeLog,
} from "@/api/modules/finance/balance";
import { refInputElemFocus } from '@/utils/domoperate'
import { debounce, getName } from '@/utils/general'

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
  },
  props: {
    currencyList: {
      type: Array,
      default: ([])
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const formRef = ref(null);
    const rechargeAmountRef = ref(null);

    const state = reactive({
      modalData: {
        visible: false,
        loading: false,
      },
      // 付款币种列表
      paymentCurrenciesList: [],
      modalLoading: true,
      modalForm: {
        fromCurrencyCode: null,
        fromCurrencyId: null,
        toCurrencyCode: null,
        rechargeAmount: "",
        payNo: "",
      },
      modalFormDetail: {
        exchangeRate: "",
        payMoney: "",
        payInfo: "",
        accountInfo: null,
      },
      payeeAccountId: '',
      inputLoading: false,
      // 付款币种
      selectedFromCurrencyCode: null,
      labelCol: { style: { width: "100px" } },
      wrapperCol: { span: 14 },
    })

    const rules = {
      fromCurrencyCode: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.payment_currency')]),
      }],
      fromCurrencyId: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.payment_method')]),
      }],
      toCurrencyCode: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.recharge_currency')])
      }],
      rechargeAmount: [{
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('finance.money')])
      }],
    }

    const getCnOrEnName = (item) => {
      return getName(item, getters.language)
    }

    const getPaymentCurrenciesList = () => {
      let list = []
      state.paymentCurrenciesList.map(item => {
        if (!list.some(ite => ite.currencyId === item.currencyId)) {
          list.push(item)
        }
      })
      return list
    }

    const handleOpen = () => {
      state.modalData.visible = true;
      state.modalLoading = true;
      nextTick(() => {
        formRef.value.resetFields()
        handleModalClear();
        const accountData = {
          currentIndex: 1,
          maxResultCount: 99,
          skipCount: 0,
        };
        getPayeeAccountList(accountData).then(({ result }) => {
          if (result) {
            let { items } = result
            if (items.length > 0) {
              state.paymentCurrenciesList = items.map((item) => {
                return {
                  id: item.id,
                  currencyId: item.currencyId,
                  key: item.currencyCode,
                  symbol: item.currencySymbol,
                  value: '',
                  cnName: item.currencyCnName,
                  enName: item.currencyEnName,
                  payeeAccountInfo: item.payeeAccountInfo,
                  payeeAccountType: item.payeeAccountType,
                };
              });
            } else {
              state.paymentCurrenciesList = []
            }
          }
        }).finally(() => {
          state.modalLoading = false;
        })
      })
    };

    const handleModalClear = () => {
      state.modalForm = {
        fromCurrencyCode: null,
        fromCurrencyId: null,
        toCurrencyCode: null,
        rechargeAmount: "",
        payNo: "",
      }
      state.modalFormDetail = {
        exchangeRate: "",
        payMoney: "",
        payInfo: "",
        accountInfo: null,
      }
    };

    const getSelectPaymentCurrenciesList = () => {
      if (state.modalForm.fromCurrencyCode) {
        const list = state.paymentCurrenciesList.filter(item => item.key === state.modalForm.fromCurrencyCode)
        return list.filter((item, index) => {
          return list.findIndex(ite => ite.payeeAccountType === item.payeeAccountType) === index
        })
      }
      return []
    }

    const handleToCurrencyCodeChange = async () => {
      try {
        if (state.modalForm.fromCurrencyId) {
          await handleFromCurrencyCodeChange();
        }
      } catch (error) {
        
      }
    }

    const handleFromCurrencyCodeChange = async () => {
      // 付款币种改变
      try {
        let fromCurrencyInfo = state.paymentCurrenciesList.find(item => item.id === state.modalForm.fromCurrencyId)
        state.modalFormDetail.accountInfo = fromCurrencyInfo.payeeAccountInfo
        if (fromCurrencyInfo) {
            state.modalFormDetail.payInfo = `${fromCurrencyInfo.key} ${fromCurrencyInfo.symbol}`
          }
        if (state.modalForm.toCurrencyCode) {
          const currencyData = {
            fromCurrencyCode: state.modalForm.fromCurrencyCode,
            toCurrencyCode: state.modalForm.toCurrencyCode,
          };
          let { result } = await getExchangeRate(currencyData);
          if (result) {
            state.modalFormDetail.exchangeRate = result.currentExchangeRate.buyExchangeRate
          }
          if (state.modalForm.rechargeAmount) {
            await handleInputMoneyChange();
          }
        }
      } catch (error) {}
    };

    const handleInputMoneyChange = async () => {
      // 金额改变
      try {
        state.inputLoading = true
        if (
          state.modalForm.fromCurrencyId &&
          state.modalForm.toCurrencyCode &&
          state.modalForm.rechargeAmount
        ) {
          const { payNo, fromCurrencyId, ...amountData } = state.modalForm;
          let { result } = await getReChargePayMoney(amountData);
          if (result) {
            state.modalFormDetail.exchangeRate = result.exchangeRate
            state.modalFormDetail.payMoney = result.payAmount
          }
        }
        refInputElemFocus(rechargeAmountRef.value.input)
      } catch (error) {

      } finally {
        state.inputLoading = false
      }
    };

    const handleClose = () => {
      handleModalClear()
      state.modalData.visible = false
    }
    
    const handleDistribution = async () => {
      try {
        let formRes = await formRef.value.validate()
        let fromCurrency = state.paymentCurrenciesList.find(item => item.id === formRes.fromCurrencyId)
        let toCurrency = props.currencyList.find(item => item.key === formRes.toCurrencyCode)
        if (fromCurrency && toCurrency) {
          const data = {
            fromCurrencyId: fromCurrency.currencyId,
            toCurrencyId: toCurrency.id,
            rechargeAmount: formRes.rechargeAmount,
            rechargeType: fromCurrency.payeeAccountType,
            payeeAccountId: formRes.fromCurrencyId,
            payNo: formRes.payNo
          }
          state.modalData.loading = true
          let { result } = await createReChargeLog(data)
          message.success(vueI18n.t('common.succeed'))
          handleClose()
          nextTick(() => {
            emit('confirm', result)
          })
        } else {
          message.error(vueI18n.t('finance.error_and_again'))
        }
      } catch (error) {
      } finally {
        state.modalData.loading = false
      }
    };

    watch(() => state.modalForm.rechargeAmount, debounce(handleInputMoneyChange, 500))

    return {
      ...toRefs(state),
      rules,
      formRef,
      rechargeAmountRef,
      getCnOrEnName,
      getSelectPaymentCurrenciesList,
      getPaymentCurrenciesList,
      handleOpen,
      handleClose,
      handleDistribution,
      handleModalClear,
      handleToCurrencyCodeChange,
      handleFromCurrencyCodeChange,
      handleInputMoneyChange,
    }
  }
})
</script>

<style scoped>

</style>