<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('common.top_up')"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    @ok="handleConfirm"
  >
    <a-spin :spinning="loading">
      <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item :label="$t('finance.recharge_currency')">{{ getCurrencyText(formState.toCurrencyCode) }}</a-form-item>
        <a-form-item :label="$t('finance.money')">{{ formState.rechargeAmount }}</a-form-item>
        <a-form-item :label="$t('finance.payment_currency')">{{ getCurrencyText(formState.fromCurrencyCode) }}</a-form-item>
        <a-form-item :label="$t('finance.payment_method')">{{ $t($enumLangkey('rechargeType', formState.rechargeType)) }}</a-form-item>
        <a-form-item :label="$t('finance.exchange_rate')">{{ formState.exchangeRate }}</a-form-item>
        <a-form-item :label="$t('finance.payment_amount')">{{ formState.fromCurrencyCode }} {{ formState.fromSymbol }} {{ formState.payAmount }}</a-form-item>
        <a-form-item :label="$t('menu.account_info')">
          <div v-if="formState.payeeAccount && formState.payeeAccount.payeeAccountInfo">
            <div v-if="formState.payeeAccount.payeeAccountInfo.accountNo">{{ $t("finance.account_number") }}: {{ formState.payeeAccount.payeeAccountInfo.accountNo }}</div>
            <div v-if="formState.payeeAccount.payeeAccountInfo.accountName">{{ $t("finance.title_of_account") }}: {{ formState.payeeAccount.payeeAccountInfo.accountName }}</div>
            <div v-if="formState.payeeAccount.payeeAccountInfo.bank">{{ $t("finance.bank_name") }}: {{ formState.payeeAccount.payeeAccountInfo.bank }}</div>
            <div v-if="formState.payeeAccount.payeeAccountInfo.swiffCode">SWIFF CODE: {{ formState.payeeAccount.payeeAccountInfo.swiffCode }}</div>
          </div>
        </a-form-item>
        <a-form-item :label="$t('finance.payment_serial_number')" name="payNo">
          <a-input v-model:value="formState.payNo" :allowClear="true" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs, ref } from 'vue'
import { Form, Input, message, Modal, Spin } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getReChargeLog, submitReChargeLog } from '@/api/modules/finance/balance'
import { getName } from '@/utils/general'

export default defineComponent({
  components: {
    AModal: Modal,
    ASpin: Spin,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
  },
  props: {
    currencyList: {
      type: Array,
      default: ([])
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const { getters } = useStore();

    const formRef = ref(null);
    
    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      formState: {
        id: null,
        toCurrencyCode: null,
        rechargeAmount: null,
        fromCurrencyCode: null,
        fromSymbol: null,
        rechargeType: null,
        exchangeRate: null,
        payAmount: null,
        payeeAccount: null,
        payNo: null,
      },
      labelCol: { style: { width: "100px" } },
      wrapperCol: { span: 14 },
    })

    const checkPayNo = async (rule, value) => {
      if (!value) {
        return Promise.reject(vueI18n.t('common.p0_is_required', [vueI18n.t('finance.payment_serial_number')]))
      }
      value = String(value)
      if (value.length > 32) {
        return Promise.reject(vueI18n.t('common.p0_incorrect_format', [vueI18n.t('finance.payment_serial_number')]))
      }
      return Promise.resolve()
    }

    const rules = {
      payNo: [{
        required: true,
        validator: checkPayNo
      }],
    }

    const getCurrencyText = (code) => {
      if (!code) return
      let currencyInfo = props.currencyList.find(item => item.key === code)
      if (currencyInfo) {
        return getName(currencyInfo, getters.language)
      }
    }

    const handleOpen = (id) => {
      state.visible = true
      state.loading = true
      nextTick(() => { formRef.value.resetFields() })
      getReChargeLog({ id }).then(({ result }) => {
        if (result) {
          for (const key in result) {
            if (Object.hasOwnProperty.call(state.formState, key)) {
              state.formState[key] = result[key]
            }
          }
        }
      }).catch(() => {}).finally(() => {
        state.loading = false
      })
    }

    const handleConfirm = async () => {
      try {
        await formRef.value.validate()
        const data = {
          id: state.formState.id,
          payNo: state.formState.payNo
        }
        state.confirmLoading = true
        await submitReChargeLog(data)
        message.success(vueI18n.t(vueI18n.t('common.succeed')))
        handleClose()
        nextTick(() => { emit('confirm') })
      } catch (error) {
        
      } finally {
        state.confirmLoading = false
      }
    }

    const handleClose = () => {
      state.visible = false
    }

    return {
      ...toRefs(state),
      formRef,
      rules,
      getCurrencyText,
      handleOpen,
      handleClose,
      handleConfirm,
    }
  }
})
</script>